@use "../../styles/partials/mixins" as *;
.board {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 80vw;
  width: 80vw;
  box-sizing: border-box;
  border: 6px solid black;
  @include tablet {
    height: 60vw;
    width: 60vw;
    max-width: 700px;
    max-height: 700px;
  }

  &--flipped {
    flex-wrap: wrap-reverse;
  }
}
