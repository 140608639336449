@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/colors" as *;

.settings {
  position: fixed;
  background-color: white;
  z-index: 2;
  bottom: 5%;
  width: 80vw;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  padding-bottom: 2rem;
  border: 4px solid black;
  margin-top: 1rem;
  transition: background-color 0.5s, color 0.5s;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  left: 0;
  transform: translate(calc(50vw - 50%), calc(0));
  animation: slide-up 0.5s;
  &--dark {
    @include dark-mode;
  }

  &__wrapper {
    display: flex;
    width: 80%;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  &__button {
    height: 2rem;
    width: 2rem;
    border-radius: 50px;
    background-color: $darkmode-black;
    border: 1px solid black;

    &--dark {
      background-color: white;
    }
  }
  &__button-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__select-container {
    display: flex;
    justify-content: center;

    align-items: center;
  }

  &__select {
    justify-content: space-between;
    display: flex;
    align-items: center;
  }

  &__text {
    margin-right: 1rem;
  }

  &__options {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 1rem;
  }

  &__close {
    cursor: pointer;
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    background-color: transparent;
    border: none;
  }
}

@keyframes slide-up {
  from {
    bottom: -100%;
  }
  to {
    bottom: 5%;
  }
}
