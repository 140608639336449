@use "variables" as *;
@use "colors" as *;

@mixin tablet {
  @media (min-width: $tablet-breakpoint) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: $desktop-breakpoint) {
    @content;
  }
}

@mixin dark-mode {
  background-color: $darkmode-black;
  color: white;
  border-color: white;
}
