@use "../../styles/partials/mixins" as *;

.signup {
  display: flex;
  width: 80%;
  margin: 0 auto;
  padding-top: 10%;
  @include tablet {
  }

  &__form-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__label {
    margin-right: 1rem;
  }

  &__input-section {
    display: flex;
    justify-content: flex-end;
    margin: 0.5rem 0;
    width: 40%;
  }

  &__input {
    width: 60%;
  }

  &__chat {
    width: 400%;
  }
}
