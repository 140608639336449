@use "../../styles/partials/mixins" as *;

.tile {
  position: relative;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 12.5%;
  height: 12.5%;
  color: black;
  transition: background-color 0.3s, color 0.3s;

  &--default {
    background-color: green;
    &--alternate {
      background-color: lightgoldenrodyellow;
    }
  }

  &--red {
    background-color: red;
    &--alternate {
      background-color: rgb(250, 200, 200);
    }
  }

  &--blue {
    background-color: rgb(84, 84, 247);
    &--alternate {
      background-color: rgb(154, 154, 242);
    }
  }

  &--highlight {
    background-color: yellow;
    color: black;
  }

  &--check {
    background-color: red;
  }

  &__piece {
    height: 60%;

    &--large {
      height: 90%;
    }
  }

  &__moveable {
    position: absolute;
    border-radius: 25px;
    height: 30%;
    background-color: orange;
    width: 30%;
  }
}
