.nav {
  box-sizing: border-box;
  background-color: lightblue;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 3rem;

  &__wrapper {
    display: flex;
    align-items: center;

    width: 13rem;
    height: 100%;
  }

  &__item {
    cursor: pointer;
    margin-right: 3rem;
    &:hover {
      text-decoration: underline;
    }
  }
  &__settings {
  }

  &__link {
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 100%;

    &:hover {
      text-decoration: underline;
    }
  }
}
