.admin {
  display: flex;
  flex-direction: column;
  &__wrapper {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 100%;
  }
}
