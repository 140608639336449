@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/colors" as *;

.home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  transition: background-color 0.5s, color 0.5s;
  z-index: 0;
  align-items: center;

  @include tablet {
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    // padding-top: 2.5%;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    @include desktop {
      width: 100%;
      margin: auto;
      justify-content: space-around;
      flex-direction: row;
      max-width: 1300px;
    }
  }

  &__board-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 66%;
    height: 100%;
    @include tablet {
      width: auto;
      justify-content: flex-start;
    }
  }

  &__intro {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 2px solid black;
    width: 80%;
    height: 100%;
    align-self: flex-start;
    margin: 1rem auto 2rem;
    text-align: center;
    padding-bottom: 1rem;
    max-width: 700px;

    @include desktop {
      margin: 0;
      width: 40%;
    }
  }

  &__image {
    width: 80%;
    object-fit: cover;
    margin: 0 5%;
  }

  &__settings {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90vw;
    height: 100%;
    @include tablet {
      width: 20%;
    }
  }

  &__title {
    margin: 1rem 0;
  }

  &__link {
    font-size: 2rem;
    text-decoration: none;
    position: relative;
    background-color: white;
    padding: 1rem;
    justify-self: flex-start;
    border: 2px solid black;
    color: red;
    border-radius: 20px;
    width: 80%;
    margin: 1rem auto;

    &--dark {
      @include dark-mode;
    }

    &:hover {
      border: 2px solid transparent;
      &::before {
        content: "";
        border-radius: 20px;

        position: absolute;
        left: -2px;
        top: -2px;
        background: linear-gradient(45deg, #fb0094, #0000ff, #00ff00, #ffff00, #ff0000, #fb0094, #0000ff, #00ff00, #ffff00, #ff0000);
        background-size: 400%;
        width: calc(100% + 4px);
        height: calc(100% + 4px);
        z-index: -1;
        animation: steam 60s linear infinite;
      }

      &::after {
        content: "";
        position: absolute;
        left: -2px;
        top: -2px;
        background: linear-gradient(45deg, #fb0094, #0000ff, #00ff00, #ffff00, #ff0000, #fb0094, #0000ff, #00ff00, #ffff00, #ff0000);
        background-size: 400%;
        width: calc(100% + 4px);
        height: calc(100% + 4px);
        z-index: -1;
        animation: steam 20s linear infinite;
        filter: blur(30px);
      }
    }
  }
}

@keyframes steam {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}
