@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/colors" as *;

.chat {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  background-color: lightgray;

  // align-self: flex-start;
  color: black;
  padding: 1rem;
  height: 58vh;
  border: 6px solid black;

  @include desktop {
    align-self: flex-start;
    width: 40%;
  }

  &__title {
    text-align: center;
  }

  &__comments {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: 80%;
  }

  &__input {
  }
  &__submit {
    margin: 0.5rem auto;
    width: 50%;
  }

  &__new-comment {
    display: flex;
    flex-direction: column;
  }
}
