@use "../../styles/partials/mixins" as *;

.user-card {
  display: flex;
  align-items: center;
  width: 80vw;
  box-sizing: border-box;
  min-height: 2rem;
  background-color: white;
  color: black;
  border: 6px solid black;
  border-top: 0;
  padding-left: 1rem;

  margin-top: 0;
  margin-bottom: auto;
  @include tablet {
    width: 60vw;
    max-width: 700px;
  }

  &--alternate {
    border-bottom: 0;
    border-top: 6px solid black;
    margin-bottom: 0;

    margin-top: auto;

    padding-bottom: 0;
    padding-left: 0;
    padding-right: 1rem;
    justify-content: flex-end;
    background-color: rgb(83, 82, 82);
    color: white;
  }

  &__scoreboard {
    display: flex;
    align-items: center;
    width: 70%;
    height: 100%;
    margin-left: 2rem;
    &--alternate {
      justify-content: flex-end;
      margin-left: 0;

      margin-right: 2rem;
    }
  }

  &__piece {
    height: 1rem;

    &--large {
      height: 2rem;
    }
  }
}
